<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="货主" prop="outboundCode">
              <el-select v-model="form.cargoOwnerCode" placeholder="请选择">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :label="item.logicWarehouseCode"
                  :value="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">概览数据</el-col>
    </el-row>
    <div style="width: 100;display: flex;justify-content: space-around;">
      <div v-for=" (val,index) in datas" :key="index" style="width:20%;text-align: center;">
        <p>{{ val.name }}</p>
        <p>{{ stockTotals[val.valu] }}</p>
      </div>
    </div>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">明细数据</span>
      <!-- <span>
        <el-button type="primary" :disabled="show" :loading="releaseLoading">
          {{ $t('title.Shortagecompletion') }}</el-button>
        <el-button>{{ $t('page.export') }}</el-button>
      </span> -->
    </el-row>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerCode" label="货主" width="180" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-inventory-details?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.cargoOwnerCode }}</router-link></template>
      </el-table-column>
      <el-table-column prop="totalQuantity" label="总库存" width="120" align="center" />
      <el-table-column prop="planQuantity" label="计划库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-planned-inventory?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.planQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="purchaseIntransitQuantity" label="采购在途库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-transit-inventory?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.purchaseIntransitQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="intransitQuantity" label="发货在途库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-delivery-transit?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.intransitQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="inStockQuantity" label="在库库存" width="120" align="center" />
      <el-table-column prop="availableQuantity" label="可用库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-available-inventory?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.availableQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="lockQuantity" label="锁定库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-lock-inventory?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.lockQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="awaitOutQuantity" label="待出库库存" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/consignor/cons-outgoing-inventory?cargoOwnerCode=${scope.row.cargoOwnerCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.awaitOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="brokenQuantity" label="不良品库存" width="120" align="center" />
    </el-table>
    <Paging :pager="pager" @update="update" />
    <!-- <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row> -->
  </div>
</template>
<script>
import Paging from '@/components/Paging'
import { cargoOwner } from '@/api/inventory-inquiry'
import { getWarehouse } from '@/api/stockin'

export default {
  components: {
    Paging
  },
  data() {
    return {
      warehouseList: [],
      headlineProductsList: [],
      form: {
        cargoOwnerCode: ''
      },
      stockTotals: [],
      datas: [
        { name: '总库存', valu: 'totalQuantity' },
        { name: '计划库存', valu: 'planQuantity' },
        { name: '采购在途库存', valu: 'purchaseIntransitQuantity' },
        { name: '发货在途库存', valu: 'intransitQuantity' },
        { name: '在库库存', valu: 'inStockQuantity' },
        { name: '可用库存', valu: 'availableQuantity' },
        { name: '锁定库存', valu: 'lockQuantity' },
        { name: '待出库库存', valu: 'awaitOutQuantity' },
        { name: '不良品库存', valu: 'brokenQuantity' }

      ],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      tableLoading: false,
      tableData: [
      ]

      // rules: {
      //   depositType: [{ required: true, message: this.$t('title.selectdeliverytype'), trigger: 'change' }],
      //   receiveType: [{ required: true, message: this.$t('title.selectdeliverymethod'), trigger: 'change' }]
      // }
    }
  },
  computed: {

    queryParams() {
      const { cargoOwnerCode } = this.form
      return Object.assign({}, this.pager, { cargoOwnerCode })
    }

  },
  mounted() {
    this._cargoOwner()
    this._queryWarehouseList()
  },
  methods: {
    async _cargoOwner(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records, totals }} = await cargoOwner(this.queryParams)
        this.pager = pager
        this.tableData = records
        this.stockTotals = totals
      } finally {
        this.tableLoading = false
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    update(val) {
      this.pager = val
      this._cargoOwner()
    }
  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
